import React, { useState, useEffect, useRef } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import HeaderLogo from "../../components/headerLogo/headerLogo"
import HeaderBack from "../../components/headerBack/headerBack"
import HeaderCart from "../../components/headerCart/headerCart"

import ProductV2 from "../../components/product/productV2"

function ProductShopify() {

  const shopifyProductId = '5847553769639'
  const base64ProductId = Buffer.from('gid://shopify/Product/' + shopifyProductId).toString('base64')

  const sizes = {
    "Length": [
      "",
      "54.72 / 139",
      "55.51 / 141",
      "56.30 / 143",
      "57.09 / 145"
    ],
    "Cest": [
      "",
      "48.82 / 124",
      "50.39 / 128",
      "51.97 / 132",
      "53.54 / 136"
    ],
    "Waist": [
      "",
      "45.67 / 116",
      "47.24 / 120",
      "48.82 / 124",
      "50.39 / 128"
    ],
    "Hip": [
      "",
      "46.06 / 117",
      "47.64 / 121",
      "49.21 / 125",
      "50.79 / 129"
    ],
    "Crotch": [
      "",
      "15 / 38",
      "15.28 / 38.8",
      "15.55 / 39.5",
      "15.83 / 40.2",
    ],
    "Inseam": [
      "",
      "27.17 / 69",
      "27.56 / 70",
      "28 / 71",
      "28.35 / 72"
    ]
  }


  return (
    <Layout>
      <SEO title="Shop - Baggage Tag Jumpsuit" />

      <ProductV2 productId={base64ProductId} sizes={sizes} />
    </Layout>
  )

} export default ProductShopify